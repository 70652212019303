import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import useGetArtworks from '../util/hooks/useGetArtworks';
import Layout from '../components/layout/Layout';
import HeroLandingPageAlt from '../components/layout/hero/HeroLandingPageAlt';
import ImageFromField from '../components/global/ImageFromField';
import CompLink from '../components/global/Link';
import ArtworkCard from '../components/global/ArtworkCard';
import { FollowBtn } from '../components/global/ActionButtons';
import {
  SimpleCarousel,
  CarouselItem,
} from '../components/global/SimpleCarousel';
import { ScrollContainer } from '../context/scrollBehavior';
import useAuth from '../util/hooks/useAuth';
import SEO from '../components/SEO';
import ExhibitionCard from '../components/global/ExhibitionCard';
import returnWithFallback from '../util/helper/returnWithFallback';
import { baseUrl } from '../util/auth/drupal';
import { ReadMore } from '../components/global/ReadMore';
import MediaCarousel from '../components/global/MediaCarousel';

const ArtistsPage = ({ data, pageContext }) => {
  useAuth();
  const [device, setDevice] = useState('mobile');
  const [noAvailableLikeThis, setNoAvailableLikeThis] = useState(false);
  const artistInfoRef = useRef(null);

  const {
    bodyContent,
    field_firstname: first_name,
    field_lastname: last_name,
    relationships,
    field_year_born: born,
    field_year_died: died,
    drupal_id,
    field_show_selling_section,
  } = pageContext;

  const {
    artworks: artworksLikeThis,
    loadingArtworks,
    setArtworkIds,
  } = useGetArtworks();

  const {
    blockContentBasic,
    contempSelling,
    allNodeMedia,
    allNodeExhibitionCurrentArtworks,
    allNodeExhibitionUpcomingArtworks,
    allNodeExhibitionPastArtworks,
    artistDetails
  } = data;

  const isHistorical =
    relationships?.field_artist_type?.name ===
    'Historical works of significance';

  const isContemp =
    relationships?.field_artist_type?.name ===
    'Established living contemporary';

  const { edges: availableArtwork } =
    data.availableArtworks && data.availableArtworks !== undefined
      ? data.availableArtworks
      : null;

  const { edges: allNodeSoldArtwork } =
    data.allNodeSoldArtwork && data.allNodeSoldArtwork !== undefined
      ? data.allNodeSoldArtwork
      : null;

  const exhibitions = [
    ...allNodeExhibitionCurrentArtworks.edges.filter(({ node }) =>
      node.relationships.field_exhib_artist_ref.some(
        (artist) => artist.field_firstname === first_name
      )
    ),
    ...allNodeExhibitionUpcomingArtworks.edges.filter(({ node }) =>
      node.relationships.field_exhib_artist_ref.some(
        (artist) => artist.field_firstname === first_name
      )
    ),
    ...allNodeExhibitionPastArtworks.edges.filter(({ node }) =>
      node.relationships.field_exhib_artist_ref.some(
        (artist) => artist.field_firstname === first_name
      )
    ),
  ].slice(0, 5);

  useEffect(() => {
    const getArtworksLikeThis = async () => {
      try {
        const res = await fetch(
          `${baseUrl}/jsonapi/artworks_like_this-artist/${drupal_id}`
        );

        if (res.ok) {
          const resData = await res.json();
          const { nodes } = resData;

          if (typeof nodes === 'object' && Object.keys(nodes).length) {
            const fieldPropSubItems = nodes;
            const the_ids = [];

            for (const property in fieldPropSubItems) {
              if (fieldPropSubItems[property].uuid) {
                the_ids.push(fieldPropSubItems[property].uuid);
              }
            }

            if (the_ids.length === 0) {
              setNoAvailableLikeThis(true);
            }

            setArtworkIds(the_ids);
          } else {
            setNoAvailableLikeThis(true);
          }
        }
      } catch (error) {
        console.log(error);
        setNoAvailableLikeThis(true);
      }
    };

    getArtworksLikeThis();
  }, [drupal_id, setArtworkIds]);

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      menu={data.allMenuMenuLink}
      locations={data.locations.edges}
      footerMenu={data.footer_menu}
      className={'artist-page'}
    >
      <ScrollContainer>
        <SEO title={`${first_name} ${last_name}`} image={relationships?.field_artist_photo?.uri?.url} />
        <section className="grid-container">
          {relationships?.field_banner?.uri?.url ? (
            <div className="lt-hero-oneup-container" style={{ maxHeight: 500 }}>
              <div className="hero_image">
                {device === 'mobile' ? (
                  <ImageFromField
                    img={returnWithFallback({
                      fallback: relationships.field_artist_listing_image,
                      optional: 'image_style_uri',
                      list_key: 'gatsby_artwork_image',
                      list: true,
                    })}
                    direct={true}
                    fallback={null}
                    style={{ maxHeight: 500, width: '100%', objectFit: 'cover' }}
                    className="mobile"
                  />
                ) : (
                  <ImageFromField
                    img={
                      relationships?.field_banner.uri?.url
                        ? returnWithFallback({
                            fallback: relationships.field_banner,
                            optional: 'image_style_uri',
                            list_key: 'banner_manual_crop',
                            list: true,
                          })
                        : relationships.field_artist_listing_image
                    }
                    direct={true}
                    fallback={null}
                    style={{ maxHeight: 500, width: '100%', objectFit: 'cover' }}
                    className="desktop"
                  />
                )}
              </div>
            </div>
          ) : (
            <HeroLandingPageAlt
              img={relationships.field_artist_listing_image}
              device={device}
              height={500}
            />
          )}
        </section>
        <article className="section artist-info">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h2 className="artist-title">
                  {first_name} {last_name}
                </h2>
              </div>
            </div>
            <div className="columns">
              {bodyContent ? (
                <div className={`column is-8 artist-content`}>
                  <ReadMore refEle={artistInfoRef}>
                    <div
                      dangerouslySetInnerHTML={{ __html: bodyContent }}
                    ></div>
                  </ReadMore>
                </div>
              ) : null}

              <div
                className={`column is-${bodyContent ? 4 : 12} artist-features`}
              >
                <div
                  ref={artistInfoRef}
                  className="columns is-multiline is-mobile"
                >
                  <div className="column artist-image">
                    <ImageFromField img={relationships.field_artist_photo} imageSize="gatsby_artist_pic" />
                  </div>
                  <div className="column artist-meta-info">
                    <ul>
                      {relationships?.field_artist_origin?.name ? (
                        <li>
                          <h4>Artist Origin</h4>
                          <p>{relationships.field_artist_origin.name}</p>
                        </li>
                      ) : null}
                      <li>
                        <h4>Artist Type</h4>
                        <p>{relationships.field_artist_type.name}</p>
                      </li>
                      {born ? (
                        <li>
                          <h4>Born</h4>
                          <p>{born}</p>
                        </li>
                      ) : null}
                      {died ? (
                        <li>
                          <h4>Died</h4>
                          <p>{died}</p>
                        </li>
                      ) : null}

                      <li>
                        <FollowBtn drupal_id={drupal_id} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {availableArtwork && availableArtwork.length > 0 ? (
            <>
              <div className="spacer"></div>
              <section>
                <div className="container">
                  <h2 className="sub-section-title">Available Artwork</h2>
                  <div className="spacer"></div>
                  <div className="artworks grid-3 grid-2-md grid-1-sm ">
                    {availableArtwork.map(({ node: artwork }, i) => (
                      <ArtworkCard
                        artwork={artwork}
                        device={device}
                        key={i}
                        artist_drupal_id={drupal_id}
                        className="grid-col"
                      />
                    ))}
                  </div>
                  <div className="spacer"></div>
                </div>
              </section>
            </>
          ) : null}

          {allNodeSoldArtwork && allNodeSoldArtwork.length > 0 ? (
            <section>
              <div className="container">
                <h2 className="sub-section-title">Exceptional Sales</h2>
                <div className="spacer"></div>
                <div className="artworks grid-3 grid-2-md grid-1-sm ">
                  {allNodeSoldArtwork.map(({ node: artwork }, i) => (
                    <ArtworkCard
                      artwork={artwork}
                      device={device}
                      key={i}
                      artist_drupal_id={drupal_id}
                      className="grid-col"
                    />
                  ))}
                </div>
                <div className="spacer"></div>
              </div>
            </section>
          ) : null}

          {isHistorical ? (
            <section>
              <div className="container">
                <div className="considering-selling">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blockContentBasic.body.processed,
                    }}
                  ></div>
                </div>
              </div>
            </section>
          ) : null}

          {isContemp && field_show_selling_section ? (
            <section>
              <div className="container">
                <div className="considering-selling">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contempSelling.body.processed,
                    }}
                  ></div>
                </div>
              </div>
            </section>
          ) : null}

          {exhibitions && exhibitions.length > 0 ? (
            <div className="exhibitions">
              <section className="exhibitions">
                <div className="container">
                  <h2 className="sub-section-title">Exhibitions</h2>
                  <div className="spacer"></div>

                  <SimpleCarousel>
                    {exhibitions.map((exhibition, i) => (
                      <CarouselItem>
                        <ExhibitionCard
                          exhibition={exhibition.node}
                          device={device}
                          key={i}
                        />
                      </CarouselItem>
                    ))}
                  </SimpleCarousel>
                </div>
              </section>
              <section>
                <div className="container">
                  <div className="t-right">
                    <CompLink
                      simplebutton
                      className={`btn-large btn-center btn-letterspacing`}
                      path={`/exhibitions`}
                      linkkey={`exhibit_btn`}
                      device={device}
                    >
                      View all exhibitions
                    </CompLink>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <> </>
          )}

          {allNodeMedia.edges.length ? (
            <div className="exhibitions">
              <section className="exhibitions">
                <div className="container">
                  <h2 className="sub-section-title">Media</h2>
                  <div className="spacer"></div>
                  <MediaCarousel items={allNodeMedia.edges} />
                </div>
              </section>
            </div>
          ) : null}

          {!noAvailableLikeThis ? (
            <div className="artists">
              <section className="browse-more-artists artists-cards">
                <div className="container">
                  <h2 className="sub-section-title">Suggested Artwork</h2>
                  <div className="spacer"></div>
                  {loadingArtworks ? (
                    <div className="artworks grid-4 grid-2-md grid-1-sm ">
                      {new Array(8).fill(0).map((_, i) => (
                        <div
                          key={i}
                          className="artwork-skeleton grid-col"
                        ></div>
                      ))}
                    </div>
                  ) : artworksLikeThis.length > 0 ? (
                    <div className="grid-4 artists grid-2-md grid-1-sm card">
                      {artworksLikeThis.map(({ data: artwork }) => {
                       /*  console.log('artwork card', artwork); */
                        return (
                        <ArtworkCard
                          artwork={artwork}
                          device={device}
                          key={artwork.id}
                          artist_drupal_id={
                            artwork.relationships.field_artist_ref.id
                          }
                          className="grid-col"
                        />
                      )})}
                    </div>
                  ) : null}
                  <div className="t-right">
                    <CompLink
                      simplebutton
                      className={`btn-large btn-center btn-letterspacing`}
                      path={`/artworks`}
                      linkkey={`exhibit_btn`}
                      device={device}
                    >
                      View all artworks
                    </CompLink>
                  </div>
                </div>
              </section>
            </div>
          ) : null}
        </article>
      </ScrollContainer>
    </Layout>
  );
};

export default ArtistsPage;

export const ArtistsDetailsQuery = graphql`
  fragment ExhibitionFrag on node__exhibition {
    relationships {
      field_banner {
        uri {
          url
        }
      }
      field_locations_ref {
        title
      }
      field_exhib_artist_ref {
        field_firstname
        field_lastname
      }
      field_featured_image {
        uri {
          url
        }
        image_style_uri {
          featured_image_crop
        }
      }
    }
    field_dates {
      end_value
      value
    }
    body {
      processed
    }
    path {
      alias
    }
    title
  }

  query ArtistsDetailsQuery(
    $field_firstname: String
    $field_lastname: String
    $currentDate: Date
    $the__drupal_id: String
  ) {
    blockContentBasic(
      drupal_id: { eq: "22ed3b47-1d0a-428e-b02c-5007c80637ca" }
    ) {
      body {
        processed
      }
    }
    contempSelling: blockContentBasic(
      drupal_id: { eq: "55e6da7a-909c-455a-bd95-d5cff15b6f79" }
    ) {
      body {
        processed
      }
    }
    # artist details
    artistDetails: nodeArtist(drupal_id: {eq: $the__drupal_id}) {
        relationships {
          field_artist_listing_image {
            uri {
              url
            }
            image_style_uri {
              banner_manual_crop
              block_manual_crop
              featured_image_crop
              gatsby_artist_pic
              gatsby_artwork_image
              gatsby_artwork_thumbnail
              gatsby_artwork_thumbnail
              gatsby_exhibition_teaser
              gatsby_landing_page_banner
              thumbnail
              u7_artist_node
              u7_artwork_list
              u7_artwork_node
              u7_manual_crop
            }
          }
        }
    }
    # media related to the artist
    allNodeMedia(
      filter: {
        relationships: {
          field_artists: {
            elemMatch: {
              field_firstname: { eq: $field_firstname }
              field_lastname: { eq: $field_lastname }
            }
          }
        }
      }
    ) {
      edges {
        node {
          title
          field_video
        }
      }
    }
    # stories related to the artist
    allNodeStory(
      filter: {
        relationships: {
          node__artist: {
            elemMatch: {
              field_firstname: { eq: $field_firstname }
              field_lastname: { eq: $field_lastname }
            }
          }
        }
      }
      sort: { order: DESC, fields: created }
    ) {
      edges {
        node {
          path {
            alias
          }
          title
          relationships {
            field_story_image {
              image_style_uri {
                gatsby_news_thumbnail
              }
              uri {
                url
              }
            }
          }
        }
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
    availableArtworks: allNodeArtwork(
      filter: {
        status: { eq: true }
        relationships: {
          field_artist_ref: {
            field_firstname: { eq: $field_firstname }
            field_lastname: { eq: $field_lastname }
          }
          field_artwork_status: { name: { eq: "Available" } }
        }
      }
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          drupal_id
          title
          created
          field_art_price_published
          field_art_year
          field_artist_payment
          field_artist_payment_amount
          field_artwork_name
          field_circa
          field_depth
          field_exceptional_artwork_sold
          field_height
          field_width
          field_dimensions_computed
          field_net_artwork_profit
          field_order_exceptional_sale
          title
          field_searchable_price
          field_published_sold_artwork
          field_promoted_artwork
          field_price_avail_on_request
          field_year_or_circa
          field_art_price_original {
            currency_code
            number
          }
          relationships {
            field_location_ref {
              title
            }
            field_artwork_images {
              uri {
                url
              }
              image_style_uri {
                gatsby_artwork_image
                gatsby_artwork_thumbnail
              }
            }
            field_artist_ref {
              title
              path {
                alias
              }
              relationships {
                field_artist_type {
                  name
                }
              }
            }
            field_artwork_type {
              name
            }
            field_artwork_medium {
              name
            }
            field_artwork_status {
              name
            }
          }
          path {
            alias
          }
        }
      }
    }
    allNodeSoldArtwork: allNodeArtwork(
      filter: {
        status: { eq: true }
        relationships: {
          field_artist_ref: {
            status: { eq: true }
            field_lastname: { eq: $field_lastname }
            field_firstname: { eq: $field_firstname }
          }
          field_artwork_status: { name: { eq: "Sold" } }
        }
        field_published_sold_artwork: { eq: 1 }
      }
    ) {
      edges {
        node {
          body {
            processed
          }
          drupal_id
          field_art_price_original {
            currency_code
            number
          }
          created
          field_art_price_published
          field_art_year
          field_artist_payment
          field_artist_payment_amount
          field_artwork_name
          field_circa
          field_depth
          field_exceptional_artwork_sold
          field_height
          field_width
          field_dimensions_computed
          field_net_artwork_profit
          field_order_exceptional_sale
          title
          path {
            alias
          }
          field_searchable_price
          field_published_sold_artwork
          field_promoted_artwork
          field_price_avail_on_request
          field_year_or_circa
          relationships {
            field_location_ref {
              title
            }
            field_artwork_images {
              uri {
                url
              }
            }
            field_artist_ref {
              drupal_id
              body {
                processed
              }
              title
              path {
                alias
              }
              relationships {
                field_artist_type {
                  name
                }
              }
            }
            field_artwork_type {
              name
            }
            field_artwork_medium {
              name
            }
            field_artwork_status {
              name
            }
          }
        }
      }
    }
    artworkCount: allNodeArtwork {
      totalCount
    }
    allNodeExhibitionCurrentArtworks: allNodeExhibition(
      filter: {
        field_dates: { end_value: { gte: $currentDate } }
        status: { eq: true }
      }
      limit: 5
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          ...ExhibitionFrag
        }
      }
    }

    allNodeExhibitionUpcomingArtworks: allNodeExhibition(
      filter: {
        field_dates: {
          value: { gte: $currentDate }
          end_value: { lte: $currentDate }
        }
        status: { eq: true }
      }
      limit: 5
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          ...ExhibitionFrag
        }
      }
    }

    allNodeExhibitionPastArtworks: allNodeExhibition(
      filter: {
        field_dates: { end_value: { lte: $currentDate } }
        status: { eq: true }
      }
      limit: 20
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          ...ExhibitionFrag
        }
      }
    }
  }
`;
