import React from 'react';
import ReactPlayer from 'react-player';

const MediaCard = ({ media, isSliderActive }) => {
  const { title, field_video } = media;

  return (
    <>
      <div className="media-card">
        <div className="iframe-wrapper">
          <ReactPlayer
            style={{
              position: `absolute`,
              left: 0,
              top: 0,
            }}
            width={`100%`}
            height={`100%`}
            url={field_video}
            onPlay={() => (isSliderActive ? isSliderActive(false) : '')}
            onPause={() => (isSliderActive ? isSliderActive(true) : '')}
            onEnded={() => (isSliderActive ? isSliderActive(true) : '')}
          />
        </div>
        <h3 className="t-large t-subheading t-uppercase exh-title">{title}</h3>
      </div>
    </>
  );
};

export default MediaCard;
