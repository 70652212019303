import React, { useState } from 'react';
import MediaCard from './MediaCard';
import { CarouselItem, SimpleCarousel } from './SimpleCarousel';

const MediaCarousel = ({ items }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <SimpleCarousel sliderActive={isActive}>
      {items.map(({ node: media }, i) => {
        return (
          <CarouselItem key={i}>
            <MediaCard isSliderActive={setIsActive} media={media} />
          </CarouselItem>
        );
      })}
    </SimpleCarousel>
  );
};

export default MediaCarousel;
