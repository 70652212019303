import React from 'react'
import Link from '../../global/Link'
import ImageFromField from '../../global/ImageFromField'

const HeroLandingPageAlt = ({img, title, path, device, height}) => (
    <React.Fragment>
        <div className="lt-hero-oneup-container" style={{maxHeight: height}}>
            <div className="hero_image">
                <ImageFromField img={img} fallback={null} style={{maxHeight: height, width: '100%', objectFit: 'cover'}} />  
            </div>
            {title ? (
                <div className="hero_content">
                    <div className="grid-content-container">
                        <h1 className="t-heading t-xxlarge t-left t-center-sm">{title}</h1>
                        <Link 
                            button
                            className={`btn-outline btn-light btn-xlarge`}
                            path={path}
                            linkkey={`hl_historic`}
                            device={device}
                        >
                            View More
                        </Link>
                    </div>
                </div>
            ) : null}
        </div>
    </React.Fragment>
)

export default HeroLandingPageAlt